import React, { Component } from 'react';
import axios from 'axios';
import { dbURL } from '../Constants';
import '../Art.css';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreReact from 'read-more-react';
import ReactPlayer from "react-player";

class ArtHistoryTimeline extends Component {

    state = {
        rowAllData: [],
        SearchString: ''
      }
    
      getPeriodItemsAll(){
        axios.get(dbURL + 'arthistory')
          .then(response => {
            this.setState({
              rowAllData: response.data
            });
          });
      } 

      
    
    componentDidMount() {
        this.getPeriodItemsAll()
      } 


      handleSearchChange = this.handleSearchChange.bind(this);

      handleSearchChange(event) {
      this.setState({SearchString: event.target.value});
      } 
  
      clearSearchField(){
      this.setState({
          SearchString: '', 
          })
      }

      scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    scrollToBottom = () => {
        scroll.scrollToBottom();
        };

    render() {

        const AllItems = this.state.rowAllData;
        let content;

        const ArtHistoryItems = AllItems.map(value => {

            return (

                <React.Fragment key={value.id}>
                    
                    <div className="row ArtTitle">
                        <div className="col-sm-4"> 
                            <div>&nbsp;<b>{value.period_movement}</b></div>
                        </div>
                        <div className="col-sm-8"> 
                            <div>&nbsp;<b>{value.time}</b></div>
                        </div>
                    </div>    

                    <div className="row pb-4 pt-2">

                        <div className="col-sm-3">
                                <img src={value.picture_link} alt=""
                                            className="rounded mx-auto d-block"></img>

                                <br />
                                <p align="center"><b>YouTube</b></p>
                                <ReactPlayer
                                    url={ value.youtube }
                                    light='true'
                                    //light
                                    controls
                                    width="250"
                                    height="250"
                                />
                        </div>

                        <div className="col-sm-4">

                            <div className="Description">
                                <ReadMoreReact text={value.description}
                                    //min={1}
                                    ideal={750}
                                    max={1000}
                                    readMoreText="<click for more>"
                                    /><br />
                            </div>

                            <div className="bg-light ml-2 mr-2 py-1 px-2 factText">
                                            <b>Characteristics:</b><br />
                                            <ReadMoreReact text={value.characteristics}
                                                //min={1}
                                                ideal={500}
                                                max={500}
                                                readMoreText="<click for more>"
                                                />
                            </div>
                        </div>

                        <div className="col-sm-3">

                            {
                                (() => {
                                if (value.chief_artists) {
                                    return <div>
                                            <b>Chief Artists</b> <br />
                                             <div className="factText px-1">{value.chief_artists}</div>
                                             <br />
                                        </div>
                                
                                }
                                return 
                                })()
                            }
                                    
                                    
                                    <div>
                                    <b>Major Works</b> <br />
                                    <div className="factText px-1">{value.major_work}</div>
                                    </div>

                          </div>  

                        <div className="col-sm-2">

                            <div>
                                <b>Historical Events</b> <br />
                                <div className="factText px-1">{value.history}</div>
                            </div>


                        </div>



                    </div>

                </React.Fragment>
                )
            }
        )

        content = <main className="my-1 py-1">

                    <div className="container">  

                        <div className="row">

                            <div className="col">
                                <h1>History of art</h1>
                                <div className="row">
                                    <div className="col pb-2" align="Right"> 
                                        <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToBottom}>
                                            <b>Scroll down</b>
                                        </button>
                                    </div>
                                </div>
                                {ArtHistoryItems}
                                <p align="Right"><br />
                                    <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToTop}>
                                        <b>Scroll to top</b>
                                    </button></p>
                            </div>

                        </div>

                    </div>

                </main>
    
        return (
            
            <div>
                {content}
                
            </div>
        )

    }

}

export default ArtHistoryTimeline;