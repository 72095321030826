import React, { Component } from 'react';
import axios from 'axios';
import { dbURL } from '../Constants';
import '../Art.css';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreReact from 'read-more-react';
//import ReactPlayer from "react-player";

class ArtPeople extends Component {

    state = {
        rowAllData: [],
        SearchString: ''
      }
    
      getPeopleItemsAll(){
        axios.get(dbURL + 'artpeople')
          .then(response => {
            this.setState({
              rowAllData: response.data
            });
          });
      } 

      
    
    componentDidMount() {
        this.getPeopleItemsAll()
      } 


      handleSearchChange = this.handleSearchChange.bind(this);

      handleSearchChange(event) {
      this.setState({SearchString: event.target.value});
      } 
  
      clearSearchField(){
      this.setState({
          SearchString: '', 
          })
      }

      scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    scrollToBottom = () => {
        scroll.scrollToBottom();
        };

    render() {

        const AllPeople = this.state.rowAllData;
        const { SearchString } = this.state;
    
        let content, filterArray


        function SearchPeople(searchText) { 
            let searchArray = [];
            
            for (let i = 0; i < AllPeople.length; i++) {

                if (AllPeople[i].searchname.includes(searchText))
                
                    {searchArray.push(AllPeople[i]);}
                    //console.table(filterArrayYear);
            } 
            //console.log(searchArray);
            return searchArray
        }

        if (SearchString) {
            filterArray = SearchPeople(SearchString.toLowerCase());
            }  
        else filterArray = AllPeople;

        

        const ArtPeopleItems = filterArray.map(value => {



            return (

                <React.Fragment key={value.id}>
                    
                    <div className="row ArtTitle">
                        <div className="col"> 
                            <div>&nbsp;<b>{value.name}</b> ({value.born||value.bd_text} - {value.dead||value.dd_text})
                               
                            
                            </div>
                        </div>
                        
                    </div>    

                    <div className="row pb-4 pt-2">

                        <div className="col-sm-3">
                                <img src={value.picture} alt="" height="200" width="200" 
                                            className="rounded mx-auto d-block"></img>

                                <br />
                            
                                    <b>Born location</b> <br />
                                    <div className="factText px-1">{value.born_place_txt}</div>
                                
                                
                        </div>

                        <div className="col-sm-6">

                            <div className="Description">
                                <ReadMoreReact text={value.description}
                                    //min={1}
                                    ideal={800}
                                    max={800}
                                    readMoreText="<click for more>"
                                    /><br />
                                    <p className="Description"> 
                                         <em> -> <a href={value.wiki}>wikipedia..</a></em>
                                    </p>
                            </div>

                           
                        </div>

                        <div className="col-sm-3">
                                    
                                    <div>
                                    <b>Nationality</b> <br />
                                    <div className="factText px-1">{value.nationality}</div>
                                    </div>

                                    <div>
                                    <b>Known for</b> <br />
                                    <div className="factText px-1">{value.known_for}</div>
                                    </div>
                                   
                                    <div>
                                    <b> Notable work</b> <br />
                                    <div className="factText px-1">{value.notable_work}</div>
                                    </div>
                                    
                                    <div>
                                    <b> Art Period/movement</b> <br />
                                    <div className="factText px-1">{value.peiode_movement_txt}</div>
                                    </div>
                          </div>  

                       



                    </div>

                </React.Fragment>
                )
            }
        )

        content = <main className="my-1 py-1">

                    <div className="container">  

                        <div className="row">

                            <div className="row border-bottom border-info pb-2">

                                <div className="col-sm-8">
                                <h1>Art People ALL</h1>
                                
                                <div className="btn btn-secondary btn-sm">total count: {filterArray.length}</div>

                                
                                
                                
                            </div>

                                <div className="col-sm-4">
                                <div className="row">   
                                    <div className="col">
                                        <h4>Search</h4>
                                    </div>
                                </div>
                                <div className="row pb-2">  
                                        <div className="col-9">
                                        <input 
                                            className="form-control" 
                                            type="text" 
                                            value={SearchString} 
                                            onChange={this.handleSearchChange} 
                                            placeholder="Search people" 
                                        />
                                        </div>
                                        <div className="col-3 pl-0">
                                            <button type="button" className="btn btn-primary"  
                                            onClick={() => this.clearSearchField()} >Reset</button>
                                        </div>
                                </div>

                            </div>

                            </div>

                            <div className="row mt-3">
                                    <div className="col pb-2" align="Right"> 
                                        <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToBottom}>
                                            <b>Scroll down</b>
                                        </button>
                                    </div>
                            </div>

                            {ArtPeopleItems}

                            <div className="row">
                                    <div className="col pb-2" align="Right"> 
                                        <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToTop}>
                                            <b>Scroll to top</b>
                                        </button>
                                    </div>
                            </div>

                        </div>

                    </div>

                </main>
    
        return (
            
            <div>
                {content} 
            </div>
        )

    }

}

export default ArtPeople;