import React, { Component } from "react";
import Header from './components/Header1';
//import Home from "./components/Home";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import ArtHistoryTimeline from "./components/history/timeline";
import ArtPeople from "./components/people/AllArtPeople";
import ArtWork from "./components/art/AllArtWork";


class App extends Component {
  
  render() {
      return (

        <div className="App">

          <Router>

            <Header />

            <Switch>

              {/* <Route
                exact
                path="/"
                render={() => (
                  <Home />
                )}
              /> */}
               <Route
                exact
                path="/"
                render={() => (
                  <ArtHistoryTimeline />
                )}
              /> 
              <Route exact path="/artwork">
                  <ArtWork />
              </Route>

              <Route exact path="/artpeople">
                  <ArtPeople />
              </Route>
              <Route exact path="/arthistorytimeline">
                  <ArtHistoryTimeline />
              </Route>

            </Switch>

          </Router>

        </div>
      )
    }
  }

export default App;
    
  


