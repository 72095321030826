import React from 'react';
//import { Link } from "react-router-dom";
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';

const TEST = process.env.REACT_APP_ART_SITE;

const Header = () => (



    <Navbar bg="light" expand="lg">
        <Container>
            <Navbar.Brand href="/">
                    <img
                    alt=""
                    src="/images/art-icon.jpg"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    />{' '}
                ArtThisDay 
             </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    {/* <Nav.Link href="#home">Home</Nav.Link>
                    <Nav.Link href="#link">Link</Nav.Link> */}
                    <NavDropdown title="Artists" id="basic-nav-dropdown">
                        <NavDropdown.Item href="/artpeople">All </NavDropdown.Item>
                        {/*<NavDropdown.Item href="/moremolecule">More Molecule</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
                    </NavDropdown>
                    <Nav.Link href="/artwork">Art</Nav.Link>
                    {TEST === "true" &&
                        <Nav.Link href="/artwork">Art test env</Nav.Link>
                    }
                    {TEST !== "true" &&
                        <Nav.Link href="/artwork">Art test env not true</Nav.Link>
                    }
                    
                </Nav>
            </Navbar.Collapse>
            
            
        </Container>
    </Navbar>


    
)

export default Header;