import React, { Component } from 'react';
import axios from 'axios';
import { dbURL } from '../Constants';
import '../Art.css';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreReact from 'read-more-react';
import Select from 'react-select';
//import ReactPlayer from "react-player";

class ArtWork extends Component {

    state = {
        rowAllData: [],
        SearchString: '',
        selectedOptionArtist: null
      }
    
      getArtItemsAll(){
        axios.get(dbURL + 'artworkall')
          .then(response => {
            this.setState({
              rowAllData: response.data
            });
          })
      } 

      
    
    componentDidMount() {
        this.getArtItemsAll()
      } 


      handleSearchChange = this.handleSearchChange.bind(this);

      handleSearchChange(event) {
      this.setState({SearchString: event.target.value});
      } 
  
      clearSearchField(){
      this.setState({
          SearchString: '', 
          })
      }

      selectedOptionArtist = (selectedArtist) => {
        this.setState({ selectedOptionArtist: selectedArtist });
        } 
    
    clearFilterArtist(){
        this.setState({
            selectedOptionArtist: null, 
            })
        }

      scrollToTop = () => {
        scroll.scrollToTop(); 
        };

    scrollToBottom = () => {
        scroll.scrollToBottom();
        };

    render() {

        const AllArtItems = this.state.rowAllData;
        const { SearchString } = this.state;
        const { selectedOptionArtist } = this.state;
    
        let content, filterArray


        function SearchArt(searchText) { 
            let searchArray = [];
            
            for (let i = 0; i < AllArtItems.length; i++) {

                if (AllArtItems[i].searchtitle.includes(searchText))
                
                    {searchArray.push(AllArtItems[i]);}
                    //console.table(filterArrayYear);
            } 
            //console.log(searchArray);
            return searchArray
        }

        if (SearchString && !selectedOptionArtist) {
            filterArray = SearchArt(SearchString.toLowerCase());
            }  
        if (selectedOptionArtist) {
            filterArray = AllArtItems.filter(e => e.artist === selectedOptionArtist.value);
            } 
            
        if  (!selectedOptionArtist && !SearchString) {
            filterArray = AllArtItems;
            } 

        //get only artists
        const OnlyArtists = AllArtItems.map(item => item.artist);
        
        //unique artist + count
        const uniqueArtists = OnlyArtists.reduce((b,c) => (
            (b[b.findIndex(d => d.artist===c)] ||
            // eslint-disable-next-line 
            b[b.push({artist: c, count: 0})-1]).count++,b), 
            []
        );
        const uniqueArtistsSorted = uniqueArtists.sort(function(a, b){
            return b.count-a.count
        })
        
        //map Artists with count to ArtistOptions
        const ArtistOptions = uniqueArtistsSorted.map(value => { 
            return {value: value.artist, label: value.artist + ' (' + value.count + ')'}; 
        });
        

        const ArtItems = filterArray.map(value => {



            return (

                <React.Fragment key={value.id}>
                    
                    {/* <div className="row ArtTitle">
                        <div className="col"> 
                            <div>&nbsp;<b>{value.name}</b> ({value.born||value.bd_text} - {value.dead||value.dd_text})
                               
                            
                            </div>
                        </div>
                        
                    </div>  */}   

                    <div className="row pb-4 pt-2">

                        <div className="col-sm-3">

                                
                                <a href={value.picture_link_large}  target="_blank" rel="noopener noreferrer">
                                    <img src={value.picture_link} alt="" 
                                            className="rounded mx-auto d-block"></img>
                                </a>

                                <p align="center">by <b>{value.artist}</b> ({value.nationality})</p>
  
                        </div>

                        <div className="col-sm-6">
                            <p className='Title' align="center"><b>{value.title}</b></p>
                            
                            <div className="Description2">
                                <ReadMoreReact text={value.description}
                                    //min={1}
                                    ideal={800}
                                    max={800}
                                    readMoreText="<click for more>"
                                    /><br />
                                    <p className="Description2"> 
                                         <em> -> <a href={value.wikipedia}>wikipedia..</a></em>
                                    </p>
                            </div>

                           
                        </div>

                        <div className="col-sm-3">
                                    
                                    <div>
                                    <b>Year</b> <br />
                                    <div className="factText px-1">{value.year_txt}</div>
                                    </div>

                                    {
                                        (() => {
                                        if (value.medium) {
                                            return <div>
                                                    <b>Medium</b> <br />
                                                    <div className="factText px-1">{value.medium}</div>
                                                    </div>
                                        }
                                        return 
                                        })()
                                    }
                                    
                                    

                                    <div>
                                    <b>Dimensions</b> <br />
                                    <div className="factText px-1">{value.dimensions}</div>
                                    </div>
                                   
                                    <div>
                                    <b>Location</b> <br />
                                    <div className="factText px-1">{value.location} - {value.city}, {value.country}</div>
                                    </div>

                                    {
                                        (() => {
                                        if (value.peiode_movement_txt) {
                                            return <div>
                                                    <b>Art Period/movement</b> <br />
                                                    <div className="factText px-1">{value.peiode_movement_txt}</div>
                                                    </div>
                                        }
                                        return <div>
                                                <b>Art Period/movement</b> <br />
                                                <div className="factText px-1">{value.period_movement}</div>
                                                </div>
                                        })()
                                    }
                                    
                                    
                          </div>  

                       



                    </div>

                </React.Fragment>
                )
            }
        )

        content = <main className="my-1 py-1">

                    <div className="container">  

                        <div className="row">

                            <div className="row border-bottom border-info pb-2">

                                <div className="col-sm-8">
                                <h1>Art Work</h1>
                                
                                <div className="btn btn-secondary btn-sm">total count: {filterArray.length}</div>

                                
                                
                                
                            </div>

                                <div className="col-sm-4">

                                <div className="row pb-2">  
                                    <div className="col-7">
                                            <Select
                                                value={selectedOptionArtist}
                                                onChange={this.selectedOptionArtist}
                                                options={ArtistOptions}
                                                placeholder='Select Artist'
                                            />
                                    </div>
                                    <div className="col-5"> 
                                        <button type="button" className="btn btn-primary"  onClick={() => this.clearFilterArtist()} >Reset filter</button>
                                    </div>
                                </div>

                                    {!selectedOptionArtist && 
                                    <div>
                                        <div className="row">   
                                            <div className="col">
                                                <h4>Search</h4>
                                            </div>
                                        </div>
                                        <div className="row pb-2">  
                                            <div className="col-9">
                                            <input 
                                                className="form-control" 
                                                type="text" 
                                                value={SearchString} 
                                                onChange={this.handleSearchChange} 
                                                placeholder="Search art title" 
                                            />
                                            </div>
                                            <div className="col-3 pl-0">
                                                <button type="button" className="btn btn-primary"  
                                                onClick={() => this.clearSearchField()} >Reset</button>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>

                            </div>

                            <div className="row mt-3">
                                    <div className="col pb-2" align="Right"> 
                                        <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToBottom}>
                                            <b>Scroll down</b>
                                        </button>
                                    </div>
                            </div>

                            {ArtItems}

                            <div className="row">
                                    <div className="col pb-2" align="Right"> 
                                        <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToTop}>
                                            <b>Scroll to top</b>
                                        </button>
                                    </div>
                            </div>

                        </div>

                    </div>

                </main>
    
        return (
            
            <div>
                {content} 
            </div>
        )

    }

}

export default ArtWork;